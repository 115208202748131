@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap');
@import './styles/globals.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: var(--copy);
}
body {
  height: 100vh;
  width: 100vw;
  color: var(--copy);
}
#root {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1300px) {
  html {
    font-size: 14px;
  }
}
/* Globally apply some styles to NvInput component */
input[class*='_inputArea_'] {
  padding: 0.5em;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}
::-webkit-scrollbar-track {
  background: var(--baseLight);
}
::-webkit-scrollbar-thumb {
  background: var(--accent);
}
* {
  scrollbar-color: /* thumb */ var(--accent) /*track*/ var(--baseLight);
  scrollbar-width: thin;
}
