body {
  --shadowDark: rgba(0, 0, 0, 0.15);
  --shadowLight: rgba(255, 255, 255, 0.2);
  --paddingXXL: 48px;
  --paddingXL: 40px;
  --paddingL: 32px;
  --padding: 16px;
  --paddingS: 8px;
  --primary: #f08017;
  --secondary: var(--baseDark, #f5f5f5);
  --danger: #dc3445;
  --borderSeperator: 1px solid var(--shadowDark);
}
