// For more see https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/ui/theme-customization.html#customization-with-css-variables
:root {
  /* -- Overrides generic colors. ------------------------------------------------------------- */
  --ck-color-text: var(--copy);
  --ck-color-focus-border: var(--accent);
  --ck-color-focus-outer-shadow: var(--baseDark);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */
  --ck-color-button-default-background: var(--baseLight);
  --ck-color-button-default-hover-background: var(--baseDark);
  --ck-color-button-default-active-background: var(--baseDark);

  --ck-color-button-on-background: var(--baseDark);
  --ck-color-button-on-hover-background: var(--baseDark);
  --ck-color-button-on-active-background: var(--baseDark);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */
  --ck-color-dropdown-panel-background: var(--baseLight);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */
  --ck-color-split-button-hover-background: var(--baseDark);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */
  --ck-color-input-background: var(--baseLight);
  --ck-color-input-text: var(--copy);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */
  --ck-color-labeled-field-label-background: var(--baseLight);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */
  --ck-color-list-background: var(--baseDark);
  --ck-color-list-button-on-background: var(--accent);
  --ck-color-list-button-on-background-focus: var(--accent);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */
  --ck-color-panel-background: var(--baseLight);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */
  --ck-color-toolbar-background: var(--baseLight);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */
  --ck-color-link-default: var(--accent);
}

.ck-editor__top {
  margin-bottom: var(--padding) !important;
  .ck-toolbar {
    border-bottom-width: 1px !important;
  }
}
.ck-editor {
  color: #13100d;
}
.ck-button.ck-on {
  border: 1px solid var(--shadowLight);
}
