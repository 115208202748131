.tooltip_app_frame_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--paddingS);
}
.tooltip_app_frame {
  flex: 1 0 auto;
  background: white;
  border: 0;
}
